/* You can add global styles to this file, and also import other style files */

$tabsBackgroundColor: var(--conferencetabsBackgroundColor);
$activeTabBottomColor: var(--conferenceactiveTabBottomColor);
$activeTabTextColor: var(--conferenceactiveTabTextColor);
//LEFT
$LeftHeaderButtonBackgroundColor: var(--lefttabsheaderButtonBackgroundColor);
$LeftHeaderButtonTextColor: var(--lefttabsheaderButtonTextColor);
$LeftSelectedHeaderButtonBackgroundColor: var(--lefttabsselectedHeaderButtonBackgroundColor);
$LeftBackgroundColor: var(--lefttabsbackgroundColor);
$colorFrameInputQNA: var(--leftqnacolorFrameInputQNA);
$colorTxtQNA:var(--leftqnacolorTxtQNA);
$colorFrameInputVoting: var(--leftvotescolorFrameInputVoting);
$colorTxtVoting:var(--leftvotescolorTxtVoting);
@import '~@angular/material/theming';
$custom-typography: mat-typography-config($font-family: '"Myriad Pro", sans-serif');
@include mat-core($custom-typography);
body {
    font-family: 'Myriad Pro', sans-serif !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(235, 235, 235, 0.104);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #f5f5f520;
}

::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f515;
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
button:focus {
    outline: none;
}

#main-header>mat-tab-header {
    width: 50%;
    margin: 12px auto 0 auto;
    border-radius: 1rem;
}

@media (max-width: 768px) {
    #main-header>mat-tab-header {
        width: 99%;
    }
}

#main-header>mat-tab-header>.mat-tab-label-container>.mat-tab-list>.mat-tab-labels {
    background-color: $tabsBackgroundColor;
    background-image: $tabsBackgroundColor;
    // border-radius: 0.75rem;
}

#main-header>.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    background-color: $tabsBackgroundColor;
    background-image: $tabsBackgroundColor;
    &.mat-tab-header-pagination-chevron {
        border-color: $activeTabTextColor !important;
    }
}

#main-header>mat-tab-header>.mat-tab-label-container>.mat-tab-list>mat-ink-bar {
    border-bottom: solid 5px $activeTabBottomColor;
}

#main-header>mat-tab-header>.mat-tab-label-container>.mat-tab-list>.mat-tab-labels>.mat-tab-label-active {
    opacity: 1 !important;
    color: $activeTabTextColor;
    & .icon-svg {
        fill: $activeTabTextColor !important;
    }
}

#left-tabgroup>mat-tab-header>.mat-tab-label-container>.mat-tab-list>.mat-tab-labels {
    background-color: $LeftHeaderButtonBackgroundColor;
    background-image: $LeftHeaderButtonBackgroundColor;
    & .mat-tab-label-content {
        color: $LeftHeaderButtonTextColor !important;
    }
}

#left-tabgroup>mat-tab-header>.mat-tab-label-container>.mat-tab-list>mat-ink-bar {
    border-bottom: solid 7px $LeftSelectedHeaderButtonBackgroundColor;
}

#left-tabgroup>.mat-tab-body-wrapper>.mat-tab-body>.mat-tab-body-content {
    background-color: $LeftBackgroundColor;
}

// #left-qna>div>mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-outline,
// .mat-focused .mat-form-field-outline {
//     color: $colorFrameInputQNA !important;
// }
#left-votes .mat-focused .mat-form-field-underline .mat-form-field-ripple {
    color: $colorFrameInputVoting !important;
}

// #left-qna>div>mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix>input {
//     color: $colorTxtQNA !important;
// }
#left-votes input.mat-input-element {
    color: $colorTxtVoting !important;
}

// Full calendar
.fc-scrollgrid-section-header {
    .fc-scroller {
        overflow: hidden !important;
        .fc-col-header {
            width: 100% !important;
        }
    }
}

.fc-scrollgrid-section-body {
    .fc-daygrid-body {
        width: 100% !important;
        .fc-scrollgrid-sync-table {
            width: 100% !important;
        }
    }
}

.fc-event-main-frame {
    width: 100%;
}

.calendar-option {
    text-decoration: none;
    i {
        font-size: 22px;
    }
    display: flex !important;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;
    &.google:hover {
        color: #DB4437
    }
    &.outlook:hover {
        color: #0072c6
    }
    &.yahoo:hover {
        color: #6001d2
    }
    &.apple:hover {
        color: #555555
    }
}

.default-margin-top{
	margin-top: 104px;
}

.mat-checkbox-layout {
    white-space: normal !important;
}